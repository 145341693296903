import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2019-07-01T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Art`}</strong>{` Is for expression `}</p>
    <p><strong parentName="p">{`Science`}</strong>{` is for exploration `}</p>
    <p><strong parentName="p">{`Engineering`}</strong>{` is for invention`}</p>
    <p><strong parentName="p">{`Design`}</strong>{` is for communication`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      